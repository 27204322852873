<!-- 视图 -->
<template>
  <div class="chooseView">
    <!-- 切换视图类型 -->
    <div v-if="viewsTypeList.length > 0">
      <div v-if="viewsTypeList.length > 1">
        <el-popover
          placement="bottom"
          trigger="click"
          :visible-arrow="false"
          ref="changeViewPopper"
          popper-class="view-list-popper changeViewPopper"
          v-model="changePopBool"
        >
          <!-- 当前视图类型按钮 -->
          <el-button
            plain
            size="small"
            class="change-view-button buttonClass"
            slot="reference"
            @mouseover.native="viewIconOver"
            @mouseleave.native="viewIconLeave"
          >
            <span class="current-view-icon">
              <svg class="icon icon-button" aria-hidden="true">
                <use :href="IconView"></use>
              </svg>
            </span>
            <svg class="icon dropdownIcon" aria-hidden="true">
              <use :href="IconSelect"></use>
            </svg>
          </el-button>
          <!-- 视图类型列表 -->
          <ul class="optionList">
            <li
              v-for="(item, idx) in viewsTypeList"
              :key="item.icon"
              class="item view-type-item"
              :class="{
                active: isActive == idx,
              }"
              @click="changeViewType(item, idx)"
            >
              <svg class="icon icon-button" aria-hidden="true">
                <use
                  :href="
                    currentViewIcon === item.icon
                      ? `#icon-Click-${item.icon}`
                      : `#icon-Normal-${item.icon}`
                  "
                ></use>
              </svg>
              {{ item.name }}
            </li>
          </ul>
        </el-popover>
      </div>
      <div v-else>
        <el-button size="small" class="filterBtn">
          <svg class="icon icon-button" aria-hidden="true">
            <use :href="`#icon-Normal-${viewsTypeList[0].icon}`"></use>
          </svg>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description 视图列表
 * 功能：
 * 1、展示当前视图
 * 2、视图搜索
 * 3、切换视图
 * 4、设置默认视图
 * 逻辑：
 * 1、根据传入的数据展示展示当前视图
 * 2、根据输入的关键字,模糊搜索并展示符合条件的视图搜索结果
 * 3、点击视图列表中任一数据,可切换视图,并可拖拽改变视图展示顺序
 * 4、点击视图列表右侧按钮，将当前视图设置为默认视图
 */

export default {
  props: {
    // 可切换的视图类型
    viewsTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 当前视图类型Icon图标
      currentViewIcon: "List",
      // 视图类型按钮图标Icon
      IconView: "#icon-Normal-List",
      IconSelect: "#icon-drop-down1",
      // 控制视图类型列表的显示隐藏
      changePopBool: false,
      // 当前视图类型下标
      isActive: 0,
    };
  },
  methods: {
    /**
     * 视图类型按钮鼠标移入
     */
    viewIconOver() {
      this.IconView = `#icon-Click-${this.currentViewIcon}`;
      this.IconSelect = "#icon-Click-drop-down";
    },
    /**
     * 视图类型按钮鼠标离开
     */
    viewIconLeave() {
      if (!this.changePopBool) {
        this.IconView = `#icon-Normal-${this.currentViewIcon}`;
        this.IconSelect = "#icon-drop-down1";
      }
    },
    /**
     * 点击布局类型列表 切换布局类型
     * @param {Object} view 视图数据
     *  @param {String} index 当前视图类型下标
     */
    changeViewType(view, index) {
      this.isActive = index;
      this.currentViewIcon = view.icon;
      if (view.icon === "List") {
        this.$emit("tableView");
      } else if (view.icon === "splitscreen") {
        this.$emit("multiScreen");
      } else if (view.icon === "Kanban") {
        this.$emit("boardView");
      }
      // 关闭视图类型列表气泡
      this.$refs.changeViewPopper.doClose();
    },
  },
};
</script>

<style lang="scss">
// 自定义气泡样式
.view-list-popper {
  margin-top: 4px !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;
  // margin-left: 42px;

  .optionList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
        border-radius: 4px;
      }
    }
    .active {
      color: $--color-primary;
      background: #f3f7ff;
      border-radius: 4px;
    }

    .view-type-item {
      font-size: 14px;
    }
  }
}

.changeViewPopper {
  margin-top: 4px !important;
}

.change-view-button {
  padding: 0 !important;

  > span {
    display: inline-block;
    padding: 9px 10px;
  }

  .current-view-icon {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #dedcda;
  }
}
</style>
<style lang="scss" scoped>
.chooseView {
  position: relative;
  display: flex;
  .buttonClass {
    &:hover {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:active {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:focus {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
  }
  .filterBtn {
    padding: 8px 9px !important;
    margin-right: 8px;

    > span {
      height: 12px;
      display: inline-block;
    }
  }

  .dropdownIcon {
    margin-left: 5px;
  }
}
</style>
