import axios from '@/config/httpConfig'
// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}
// 获取市场活动成员状态选项列表值(公海池中 ButtonWrapper组件中 退回，废弃操作也用到此API)
// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
}
// 队列
export function queryQueue(data) {
    return axios.post('/objectdetail/queryQueue', data)
}
// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}
// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}
//获取币种信息
export function getCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
  }
  // 获获取选项列表值
export function GetViewGetSelectValue (data) {
    return axios.post('/view/getSelectValue', data)
  }