<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfixs">
        <div style="width: 100%">
          <!--  快速筛选器 -->
          <span style="font-size: 14px; color: #393939; line-height: 28px">{{
            $t("front-filter-module-v1-quick-filter")
          }}</span>
          <!-- 筛选 -->
          <el-button
            @click="
              viewInfos.ismodify === 'false' && isNewDisabled
                ? (dialogVisible = true)
                : searchSubmit()
            "
            type="primary"
            size="mini"
            class="filterBtn pull-right"
          >
            {{ $t("component.timeline.button.filter") }}
          </el-button>
          <!-- 清除所有 -->
          <el-button
            size="mini"
            type="text"
            class="clearAllBtn pull-right"
            @click="clearAll()"
          >
            {{ $t("label.h5.similar.opportunity.clear.all") }}
          </el-button>
        </div>
      </div>
      <div>
        <div class="form">
          <el-form>
            <el-form-item
              v-for="(val, index) in pushData"
              :key="val.id"
              :label="val.label"
              class="items"
              :class="{ hasBg: val.hasBg }"
            >
              <div class="vl">
                <!-- 清除 -->
                <p
                  v-if="val.showClear"
                  class="showClear"
                  @click="clearItem(val)"
                >
                  {{ $t("label.delete.condition") }}
                </p>
                <div class="conditions">
                  <!-- 筛选条件 结束 -->
                  <div class="val">
                    <!-- 日期（D） 日期/时间（F） 时间（T）-->
                    <div class="val-d" v-if="val.type === 'D'">
                      <el-date-picker
                        v-model="val.val"
                        size="small"
                        type="date"
                        :placeholder="$t('label.chatter.startDate')"
                        value-format="yyyy-MM-dd"
                        :picker-options="datePickerOptions"
                      >
                      </el-date-picker>
                      <span class="seperate-span"></span>
                      <el-date-picker
                        v-model="val.val1"
                        type="date"
                        :placeholder="$t('label.chatter.endData')"
                        value-format="yyyy-MM-dd"
                        :picker-options="datePickerOptions"
                      >
                      </el-date-picker>
                    </div>
                    <!--  日期/时间（F） -->
                    <div class="val-d" v-if="val.type === 'F'">
                      <el-date-picker
                        v-model="val.val"
                        type="datetime"
                        :placeholder="$t('label.chatter.startDate')"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="datetimePickerOptions"
                      >
                      </el-date-picker>
                      <span class="seperate-span"></span>
                      <el-date-picker
                        v-model="val.val1"
                        type="datetime"
                        :placeholder="$t('label.chatter.endData')"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="datetimePickerOptions"
                      >
                      </el-date-picker>
                    </div>
                    <!--  时间（T）-->
                    <div class="val-d" v-if="val.type === 'T'">
                      <el-time-picker
                        v-model="val.val"
                        value-format="HH:mm:ss"
                        :placeholder="$t('label.import.index.starttime')"
                      >
                      </el-time-picker>
                      <span class="seperate-span"></span>
                      <el-time-picker
                        v-model="val.val1"
                        value-format="HH:mm:ss"
                        :placeholder="$t('label.endtime')"
                      >
                      </el-time-picker>
                    </div>
                    <!-- 数字（N）、百分比（P）、评分（SCORE）、币种（c）、累计汇总（C） -->
                    <div
                      class="val-d"
                      v-if="
                        val.type === 'N' ||
                        val.type === 'P' ||
                        val.type === 'SCORE' ||
                        val.type === 'c' ||
                        val.type === 'C'
                      "
                    >
                      <!-- 最小值 -->
                      <el-input
                        v-model="val.val"
                        @keyup.native="proving(val)"
                        :placeholder="$t('label.minimun')"
                        clearable
                      ></el-input>
                      <span class="seperate-span"></span>
                      <!-- 最大值 -->
                      <el-input
                        v-model="val.val1"
                        @keyup.native="proving(val)"
                        :placeholder="$t('label.maximum')"
                        clearable
                      ></el-input>
                    </div>
                    <!-- 复选框（B） -->
                    <div class="val-d val-d-start" v-if="val.type === 'B'">
                      <!-- 选中 -->
                      <el-radio v-model="val.val" label="true">{{
                        $t("vue_label_normal_select")
                      }}</el-radio>
                      <!-- 未选中 -->
                      <el-radio v-model="val.val" label="false">{{
                        $t("label.notselect")
                      }}</el-radio>
                    </div>
                    <EditableCell
                      v-if="
                        val.type !== 'D' &&
                        val.type !== 'F' &&
                        val.type !== 'T' &&
                        val.type !== 'N' &&
                        val.type !== 'P' &&
                        val.type !== 'SCORE' &&
                        val.type !== 'c' &&
                        val.type !== 'C' &&
                        val.type !== 'B'
                      "
                      ref="editableCell"
                      :options="val.selector"
                      :line-index="index"
                      :value.sync="val.val"
                      :input-type="val.inputType"
                      :min="0"
                      :max="1000000000000000000"
                      :origin-type="filter"
                      :prefix="prefix"
                      :fieldId="val.fieldId"
                      :objid="val.objid"
                      :selectloading="selectloading"
                      @remoteSearch="remoteSearch(val, index)"
                      @selectes="val.fieldId === '' ? '' : selectes(val)"
                      @remoteMethods="remoteMethods"
                      @editValueChange="editValueChange"
                    >
                    </EditableCell>
                    <p
                      v-if="
                        (val.type == 'D' ||
                          val.type == 'F' ||
                          val.type == 'T' ||
                          val.type == 'N' ||
                          val.type == 'P' ||
                          val.type == 'SCORE' ||
                          val.type == 'c' ||
                          val.type == 'C') &&
                        isErro
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
    <div>
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        style="height: 91%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :fromTableView="true"
          @changeSelect="changeSelect"
        />
      </el-dialog>
    </div>
  </div>
</template>
<script>
/**
 * @description 通用列表的快速筛选器
 * 功能：
 * 1、快速筛选。
 * 2、根据不同字段类型选进行筛选
 * 逻辑：
 * 1、输入填写筛选条件，刷新视图列表。
 * 2、根据不同字段类型进行模糊匹配以及范围的筛选，刷新列表视图，视图不进行存储
 */
// import SearchTable from '@/components/Form/search-table.vue'
import operator from "@/config/enumCode/operator.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import inputType from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api.js";
import debounce from "lodash.debounce";
export default {
  components: {
    EditableCell,
  },
  props: {
    // 快速筛选的对象id
    objId: {
      type: String,
      default: "",
    },
    // 快速筛选的视图id
    viewId: {
      type: String,
      default: "",
    },
    // 视图信息
    viewInfos: {
      type: Object,
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 筛选条件
    curPushData: {
      type: Array,
      default: () => [],
    },
    // 筛选器所需的列表表头字段
    curHeader: {
      type: Object,
      default: () => null,
    },
    // 通用列表按字段筛选的字段id
    filterField: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // 下拉loading
      selectloading: false,
      // 筛选器加载loading
      loading: true,
      // 多选框选择和取消选择
      checked: false,
      // 高级筛选文本域隐藏与显示
      isTextarea: false,
      // 另存为显示与隐藏
      dialogVisible: false,
      // 单选框选择，默认全部
      radio: "0",
      // 对象视图信息
      params: {
        objId: "",
        viewId: "",
      },
      // 筛选器渲染字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          type: "",
          val: {
            value: "",
          },
          inputType: "input",
          objid: "",
        },
      ],
      // 控制清除所有按钮显示与隐藏
      showClearAll: false,
      // 筛选条件集合
      pushDatass: [],
      // 控制查找筛选的显隐
      showSearchTable: false,
      // 字段id
      fieldId: "",
      // 查找多选的索引
      rowIndex: 0,
      // 是否为新建视图
      isNewDisabled: null,
      // 是否是筛选器
      filter: "filter",
      // 富文本
      screening: false,
      // 队列选项值
      queryQueueValue: "",
      // 是否是队列选择
      isQueueSelect: false,
      // 日期的快捷选项
      datePickerOptions: {
        shortcuts: [
          {
            text: this.$i18n.t("component.timeline.label.today"), // 今天
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$i18n.t("lable.account.Yesterday"), //"昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$i18n.t("front-filter-module-v1-filter-a-week-ago"), //"一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 日期时间快捷选项
      datetimePickerOptions: {
        shortcuts: [
          {
            text: this.$i18n.t("component.timeline.label.today"), // 今天
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$i18n.t("lable.account.Yesterday"), //"昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$i18n.t("front-filter-module-v1-filter-a-week-ago"), //"一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 控制输入值得判断
      isErro: false,
    };
  },
  watch: {
    /**
     * 监听清除按钮的显隐
     * @param {Object} value 当前值
     */
    pushData: {
      handler(value) {
        let flag1 = false;
        value.forEach((item) => {
          if (item.val?.constructor === Object) {
            if (Array.isArray(item.val.value)) {
              // 选中值为数组
              if (item.val.value.length) {
                item.showClear = true;
                this.showClearAll = true;
              } else {
                item.showClear = false;
              }
            } else {
              if (item.val.value) {
                item.showClear = true;
                this.showClearAll = true;
              } else {
                item.showClear = false;
              }
            }
          } else {
            if (item.val || item.val1) {
              item.showClear = true;
              this.showClearAll = true;
            } else {
              item.showClear = false;
            }
          }
          if (item.showClear) {
            flag1 = true;
          }
        });
        if (!flag1) {
          this.showClearAll = false;
        }
      },
      deep: true,
    },
    /**
     * 监听字段是否为富文本
     * @param {Boolean} val 当前值
     */
    screening(val) {
      if (val === true) {
        this.isTextarea = true;
      } else {
        this.isTextarea = false;
      }
    },
    /**
     * 控制查找单选按钮的逻辑
     * @param {String} val 当前值
     */
    radio(val) {
      true;
      if (val === "2") {
        this.isQueueSelect = true;
      } else if (val !== "0" && val !== "1") {
        this.radio = "2";
        this.isQueueSelect = true;
        this.queryQueueValue = val;
      } else {
        this.isQueueSelect = false;
      }
    },
  },
  methods: {
    /**
     * 数字字段的验证方法
     * @param {item} 输入的值
     */
    proving(item) {
      const reg = /^\d+$|^\d+[.]?\d+$/;
      if (!reg.test(item.val)) {
        item.val = "";
      }
      if (!reg.test(item.val1)) {
        item.val1 = "";
      }
    },
    /**
     *  获取筛选器所有的已选字段
     */
    getViewListHeader() {
      //判断是否有 筛选条件
      if (this.curPushData.length) {
        this.pushData = JSON.parse(JSON.stringify(this.curPushData));
        this.loading = false;
        return;
      }
      let res = this.curHeader;
      if (res.data.headLabel !== undefined) {
        let arr = [];
        res.data.headLabel.forEach((item) => {
          // 选项列表（L）、币种类型（ct）、记录类型（R）选项列表（多选）（Q）---使用支持搜索的选项列表多选组件
          let inputTypes =
            inputType[
              item.schemefieldType === "L" ||
              item.schemefieldType === "R" ||
              item.schemefieldType === "ct"
                ? "Q"
                : item.schemefieldType === "M" || item.schemefieldType === "Y"
                ? "S"
                : item.schemefieldType
            ];
          // 选择框类型为查找多选字段时，才需要回显做处理
          if (
            inputTypes === "multi-select" ||
            inputTypes === "remote-multi-select"
          ) {
            this.checked = true;
          }
          let obj = {};
          obj = {
            fieldId: item.id,
            op: item.op === "" ? "" : item.op,
            type:
              item.schemefieldType === "Z"
                ? item.expressionType
                : item.schemefieldType,
            val: item.val ? { value: item.val } : "",
            val1: "",
            label: item.nameLabel,
            inputType: inputTypes,
            schemefieldName: item.schemefieldName,
            selector: [],
            showClear: false,
            objid: this.objId,
          };
          arr.push(obj);
        });

        this.pushData = arr;
        this.loading = false;
      }
      // 筛选器字段集合(过滤文件（FL）、标签（TAG）、加密文本(存储加密)（enc）、图片（IMG）、文本区（长）（J）、文本区（富文本）（A）、地理定位（LT）)
      this.pushData = this.pushData.filter((item) => {
        return (
          item.type !== "FL" &&
          item.type !== "TAG" &&
          item.type !== "enc" &&
          item.type !== "IMG" &&
          item.type !== "J" &&
          item.type !== "A" &&
          item.type !== "LT" &&
          item.type !== "MR" &&
          item.schemefieldName != "cloudcctag"
        );
      });
      // 按‘字段名’排序背景闪烁功能
      if (this.filterField) {
        this.pushData.map((item) => {
          if (item.fieldId == this.filterField.id) {
            item.hasBg = true;
          }
        });
      }
      this.loading = false;
    },
    /**
     *  清除所有的筛选条件
     */
    clearAll() {
      if (this.pushData) {
        this.pushData.forEach((item) => {
          if (item.val?.constructor === Object) {
            item.val = { value: "" };
            item.selector = [];
          } else {
            item.val = "";
            item.val1 = "";
          }
        });
      }
    },
    /**
     * 清楚单个筛选条件
     * @param {item} 单个筛选条件
     */
    clearItem(item) {
      if (item.val?.constructor === Object) {
        item.val = { value: "" };
        item.selector = [];
      } else {
        item.val = "";
        item.val1 = "";
      }
    },
    /**
     * 筛选条件的点击事件
     */
    searchSubmit: debounce(function () {
      // this.loading = true;
      this.pushDatass = [];
      let pushDataList = this.pushData?.length
        ? JSON.parse(JSON.stringify(this.pushData))
        : [];
      for (let j = 0; j < pushDataList.length; j++) {
        let item = pushDataList[j];
        this.pushDatass.push(item);
        if (
          item.type == "D" ||
          item.type == "F" ||
          item.type == "T" ||
          item.type == "N" ||
          item.type == "P" ||
          item.type == "SCORE" ||
          item.type == "c" ||
          item.type == "C"
        ) {
          // 区间选择
          if (item.val1) {
            let item1 = { ...item };
            item1.val = item.val1;
            delete item1.val1;
            item1.op = "m";
            delete item1.id;
            delete item1.inputType;
            delete item1.val1;
            delete item1.showClear;
            delete item1.selector;
            delete item1.label;
            delete item1.schemefieldName;
            this.pushDatass.push(item1);
          }
          // val存在且为区间选择器
          if (item.val) {
            item.op = "h";
          }
        } else if (item.type == "B") {
          item.op = "e";
        } else {
          if (
            item.type == "S" ||
            item.type == "X" ||
            item.type == "AD" ||
            item.type == "U" ||
            item.type == "encd" ||
            item.type == "E" ||
            item.type == "V" ||
            item.type == "H" ||
            item.type == "Y" ||
            item.type == "M" ||
            item.type == "MR" ||
            item.type == "L" ||
            item.type == "R" ||
            item.type == "ct" ||
            item.type == "Q"
          ) {
            // 文本（S）、文本区（X）、地址（AD）、URL（U）、加密文本(显示加密)（encd）、电子邮件（E）、自动编号（V）、电话（H）
            // 查找（Y）、主详（M）、查找多选（MR、过滤了MR）
            // 选项列表（L）、币种类型（ct）、记录类型（R）
            // 选项列表（多选）（Q）
            item.op = "c";
          }
          if (item.val?.constructor === Object) {
            item.val = Array.isArray(item.val.value)
              ? item.val.value.join(",")
              : item.val.value;
          }
        }
        delete item.id;
        delete item.inputType;
        delete item.schemefieldName;
        delete item.val1;
        delete item.showClear;
        delete item.selector;
        delete item.label;
      }
      this.pushDatass = this.pushDatass.filter((item) => {
        return item.val;
      });
      let conditionValues = JSON.stringify({ data: this.pushDatass });
      this.$emit(
        "changeConditionValues",
        conditionValues,
        JSON.parse(JSON.stringify(this.pushData))
      );
    }, 300),
    /**
     * 编辑值改变
     * @param {val} 变化的值
     * @param {lineIndex} 更改值对应的索引
     */
    editValueChange(val, lineIndex) {
      this.pushData[lineIndex].val = { value: val };
    },
    /**
     * 查询选项列表的值
     * @param {Object} val 查询条件
     */
    async selectes(val) {
      // 下拉loading
      this.selectloading = true;
      val.selector = [];
      if (val.type === "R") {
        this.getRecordTypes(val);
      } else if (val.type === "ct") {
        //币种做特殊处理

        CommonObjApi.getCurrencyInfo().then((res) => {
          res.data.forEach((item) => {
            let obj = {
              label: item.currencyname,
              value: item.currencycode,
            };
            val.selector.push(obj);
          });
          this.selectloading = false;
        });
      } else if (val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH") {
        this.getCountrySelectValue(val);
      } else {
        if (
          val.fieldId === "ffe20120329185449704" ||
          val.fieldId === "ffeleadmarketsea02"
        ) {
          this.selectloading = false;
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await CommonObjApi.GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codevalue,
              value: item.codekey,
            };
            arr.push(obj);
          });
          this.pushData.forEach((item) => {
            if (item.fieldId == val.fieldId) {
              item.selector = arr;
            } else {
              item.selector = [];
            }
          });
          this.selectloading = false;
        }
      }
    },
    /**
     * 精准搜素
     * @param {Object} item 查询条件
     * @param {Number} index 索引
     */
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 改变查找/查找带值选项及值
     * @param {Object} row 改变的行数据
     * @param {String} fieldId 字段id
     * @param {Array} formData 表单数据
     */
    changeSelect(row) {
      if (row instanceof Array) {
        let rowIds = [];
        let ids = [];
        row.forEach((val) => {
          ids.push(val.id);
          rowIds.push({
            label: val.name || val.owneridccname,
            value: val.id,
          });
        });
        this.pushData.forEach((item) => {
          if (item.fieldId == this.fieldId) {
            item.selector = rowIds;
          } else {
            item.selector = [];
          }
        });
        this.$set(this.pushData[this.rowIndex - 1], "val", { value: ids });
      } else {
        let options = {
          label: row.data.id,
          value: row.data.name,
        };
        this.$set(this.pushData[this.rowIndex - 1], "val", options);
      }
      this.showSearchTable = false;
    },
    /**
     * 获取字段类型
     * @param {Object} val
     */
    async getRecordTypes(val) {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await CommonObjApi.getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
        });
        this.pushData.forEach((item) => {
          if (item.fieldId == val.fieldId) {
            item.selector = arr;
          } else {
            item.selector = [];
          }
        });
        this.selectloading = false;
      }
    },
    /**
     * 获取国家或地区选项列表值
     * @param {Object} val
     */
    getCountrySelectValue(val) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.pushData.forEach((item) => {
            if (item.fieldId == val.fieldId) {
              item.selector = options;
            } else {
              item.selector = [];
            }
          });
          this.selectloading = false;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /**
     * 远程搜索
     * @param {String} val name值
     * @param {String} fieldId 字段id
     * @param {String} objId 对象id
     */
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.pushData.forEach((item) => {
            if (item.fieldId == fieldId) {
              item.selector = options;
            } else {
              item.selector = [];
            }
          });
        });
      });
    },
  },
  created() {
    this.params.objId = this.objId;
    this.params.viewId = this.viewId;
    this.getViewListHeader();
  },
  mounted() {
    this.logs = operator.TYPE.A.getScreeningOpeTypes();
    //定位到字段所在的位置
    let _div = document.querySelector(".hasBg");
    if (_div) {
      let _divTop = _div.getBoundingClientRect().top;
      document.querySelector(".el-form").scrollTop = _divTop - 400; //需要优化
    }
  },
  updated() {
    this.viewid = this.viewId;
  },
};
</script>

<style lang="scss">
.search-tips {
  width: 260px;
}
</style>
<style scoped lang="scss">
.clearAllBtn {
  color: #2d6cfc;
  font-size: 12px;
  margin-right: 10px;
}

.filterBtn {
  background: #2d6cfc;
  color: #ffffff;
  font-size: 12px;
}

.el-form-item.items {
  position: relative;
}
.showClear {
  font-size: 13px;
  color: #409eff;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: -27px;
}
::v-deep .btns {
  padding: 8px 16px;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0 4px 0;
  .el-row {
    flex: 1;
    .el-button {
      padding: 10px 20px;
    }
  }
  .buttonGroup {
    .el-button {
      padding: 10px 20px;
    }
  }
  .dropdown {
    .el-dropdown__caret-button {
      padding-bottom: 11px !important;
    }
  }
}
.chooseEveryone {
  padding: 15px 25px 15px 36px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

::v-deep .el-card__body {
  padding: 0;
  height: 100%;
  // overflow-y: auto;
}
::v-deep .el-card__body > div {
  height: 100%;
}
.box-card {
  width: 302px;
  height: 100%;
  position: relative;
  box-shadow: -6px 1px 11px -5px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0;

  ::v-deep .el-card__header {
    padding: 9px 20px;
    border-bottom: 1px solid #dcdcdc;
    font-size: 12px;
    color: #3e3e3c;
    .clearfixs {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .clearfixs::before,
    .clearfixs::after {
      display: none !important;
    }
  }

  .all {
    height: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 12px;
    padding-right: 7px;
    position: relative;
    // left: -11px;
  }
  .radioGroup {
    // width: 75%;
    display: flex;
    align-items: center;
    .el-radio {
      flex: 1;
    }
    .queue {
      display: flex;
      align-items: center;
    }
  }

  ::v-deep .el-radio {
    margin-right: 15px;
  }

  ::v-deep .el-radio__inner {
    width: 14px;
    height: 14px;
  }

  ::v-deep .el-radio__label {
    width: 24px;
    height: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 18px;
    padding-left: 0 !important;
  }

  ::v-deep .el-form-item__label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 18px;
    margin-bottom: 4px;
  }

  ::v-deep .form {
    width: 100%;
    position: relative;
    height: calc(100% - 122px);

    .el-form-item {
      padding: 6px 20px;
      margin-bottom: 0;
      &.hasBg {
        animation: bgTrans 3s;
      }
    }

    .el-form-item__label {
      color: #5f5e5e;
    }

    .el-form-item__content {
      width: 100% !important;
      // height: 32px; //影响textare高度
      line-height: 32px;
      display: flex;
      justify-content: center;
    }

    .el-form {
      width: 100%;
      position: absolute;
      max-height: 600px;
      overflow-y: auto;
      padding-bottom: 75px;
      height: 100%;
    }
    .el-input,
    .el-input__inner {
      height: 32px !important;
      font-size: 14px !important;
    }
    .el-input__icon {
      line-height: 32px !important;
    }
    .selects {
      width: 100%;
      .el-input__inner {
        width: 100% !important;
      }
    }

    ::v-deep .el-input__inner {
      width: 190px;
      height: 32px !important;
    }
  }

  .el-form::after {
    display: block;
    content: "";
    clear: both;
  }

  .vl {
    width: 100%;
    padding-right: 4px;
    .conditions {
      display: flex;
      .select {
        flex: 0.6;
        margin-right: 10px;
      }
      .val {
        flex: 1.4;
        ::v-deep .remoteBtn1 {
          right: 50px;
        }
      }
    }
    ::v-deep .el-range-separator {
      margin-top: -8px;
    }
    .val-d {
      display: flex;
      justify-content: space-between;
      .seperate-span {
        margin: 0 6px;
        height: 19px;
        width: 16px;
        border-bottom: 2px solid #333;
      }
      &.val-d-start {
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        ::v-deep .el-radio {
          width: 20%;
          margin-top: 10px;
        }
        ::v-deep .el-radio__input {
          margin-right: 10px;
        }
      }
    }
  }
  @keyframes bgTrans {
    from {
      background-color: #d5e2fe;
    }
    to {
      background-color: #fff;
    }
  }
  .circle {
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .info {
    margin-left: 12px;
    width: 82%;
    height: 30px;
    font-size: 16px;
    background: #006dcc;
    border: 0;

    .sp {
      text-align: center;
      width: 1px;
      height: 10px;
      color: white;
      position: relative;
      top: -5px;
    }
  }

  .item {
    margin-bottom: 5px;
  }
  .items {
    // border-bottom: 1px solid #ededed;
    position: relative;
  }

  .box_bottom {
    margin-top: 10px;
  }

  .checkbox {
    margin-bottom: 10px;
    text-align: left;
    margin-left: 29px;

    ::v-deep .el-checkbox__inner {
      width: 12px;
      height: 12px;
      margin-left: -9px;
    }

    ::v-deep .el-checkbox__label {
      width: 48px;
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #080707;
      line-height: 18px;
      /* margin-left: 15px; */
    }
  }

  .text {
    overflow: hidden;
    resize: none;
    width: 89%;
    height: 78px;
    border-radius: 3px;
    border: 1px solid rgba(216, 216, 216, 1);
    position: relative;
    left: 18px;
  }

  .preservation {
    width: 52px;
    height: 30px;
    background: rgba(0, 109, 204, 1);
    border-radius: 3px;
  }

  .deposit {
    width: 61px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(216, 216, 216, 1);
  }
}

.save {
  width: 500px;
  height: 250px;
  position: absolute;
  right: 80%;
  top: 30%;
  transform: translate(-50%, -50%);

  .saves ::v-deep input {
    border: none;
    border-bottom: 1px solid #ccc;
  }
}

::v-deep .el-alert--error.is-light {
  background: #ffffff;

  ::v-deep .el-alert__title {
    font-size: 12px;
  }
}
::v-deep .lcw {
  .el-dialog {
    z-index: 101;

    .el-dialog__body {
      .el-form {
        width: 100%;
        .el-form-item {
          width: 100%;
          margin-bottom: 0px !important;
          .el-form-item__content {
            width: 100%;
          }
        }
      }
    }
  }
}
::v-deep .el-select__tags {
  flex-wrap: unset;
  overflow-x: overlay;
  // top: 66%;
  height: 32px;
  // .el-tag {
  //   margin: 11px 7px 10px 5px;
  // }
  .el-select__input {
    height: 32px;
    min-width: 50px !important;
    margin: -5px 0px 0px 12px;
  }
}
::v-deep .displayed {
  .is-disabled {
    .el-input__inner {
      width: 100px;
    }
  }
}

// ::v-deep .dialog-form-wrapper {
//   height: 66% !important;
//   .el-dialog__body {
//     overflow: visible;
//   }
// }

::v-deep .v-modal {
  z-index: 99 !important;
}

::v-deep .date_style {
  margin-left: 0px !important;
  position: absolute;
  top: 4px;
}
.xiala {
  width: 32px;
  height: 34px;
  border: 1px solid #dedcda;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: inline-block;
  background: #fff;
  text-align: center;
  cursor: pointer;
  .icon {
    display: inline-block;
    width: 16px;
    margin-top: 8px;
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
