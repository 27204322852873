<template>
  <div class="buttonWrapper">
    <!-- 第三类按钮：自定义操作按钮 -->
    <div v-if="btnList.length > 0" class="pull-left">
      <div class="myButton" v-if="btnList.length > 1">
        <div
          class="one"
          style="cursor: pointer"
          :devid="btnList[0].id"
          @click="handleClick(btnList[0])"
        >
          {{ btnList[0].label }}  
        </div>
        <!-- 分割线 -->
        <el-divider direction="vertical"></el-divider>
        <!-- 普通操作按钮和其他按钮统一展示的下拉 -->
        <el-popover
          placement="bottom"
          trigger="click"
          :visible-arrow="false"
          ref="buttonListPopper"
          popper-class="button-list-popper"
        >
          <span slot="reference">
            <i class="el-icon-caret-bottom iBox"></i>
          </span>
          <div class="buttonListBox">
            <!-- 普通操作按钮或者其他操作按钮个数大于1的时候 -->
            <ul class="buttonList">
              <li
                v-for="(item, idx) in btnList.filter((item) => item.show)"
                v-show="idx > 0"
                :key="item.icon"
                :devid="item.id"
                class="item button-item"
                @click="handleClick(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </el-popover>
      </div>
      <div class="myButton" v-if="btnList.length === 1">
        <div class="one" :devid="btnList[0].id" @click="handleClick(btnList[0])">
          {{ btnList[0].label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 新版视图页使用的自定义按钮
 */
import * as CommonObjApi from "./api.js";
import { Loading } from "element-ui";
import DialogMixin from "@/mixin/Dialog.js";
import debounce from "lodash.debounce";

export default {
  mixins: [DialogMixin],
  components: {},
  props: {
    // 被选中的列表数据
    selectedList: {
      type: Array,
      default: () => [],
    },
    // 显示的按钮：目前拆分为两大类
    showButtons: {
      type: Array,
      default: () => [],
    },
    // 视图id，用于区分同一个类型数据的不同视图
    viewId: {
      type: String,
      default: "",
    },
    // 当前对象名称，目前不是多语言，后端翻译好了
    tabName: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 右边两类按钮：第二类，普通按钮，如：删除、编辑、发布、提交等
      btnList: [],
      // 错误数据详细信息
      tableData: [],
      // 当前对象Id
      objId: this.$route.params.objId,
      // 当前对象api标识
      objectApi: this.$route.params.objectApi,
      // 列表选中的数据
      checkedList: [],
      // 标记当前选择数据的数量
      selectNum: 0,
      // 选择数据的id集合
      ids: "",
    };
  },
  mounted() {},
  methods: {
    /**
     * 初始化
     */
    init() {
      this.btnList = [];
      this.showButtons.forEach((btn) => {
        // 新建类按钮：新建、批量新增、从模板新建
        if (
          btn.name !== "New" &&
          btn.name !== "Batch Added" &&
          btn.name !== "New from Template"
        ) {
          this.btnList.push({
            id: btn.id,
            name: btn.name,
            label: btn.label,
            functionCode: btn.functionCode,
            action: "CustomButton",
            event: btn.event,
            behavior: btn.behavior,
            show: true,
            menubar: btn.menubar,
          });
        }
      });
    },
    /**
     * 刷新页面
     */
    refresh() {
      this.$emit("refresh");
    },
    /**
     * 处理按钮点击事件,添加防抖
     * @param {Object} item 对象
     * @param {Object} action 动作
     * @param {Object} type 按钮类型
     */
    handleClick: debounce(
      function (item) {
        this.checkedList = JSON.parse(JSON.stringify(this.selectedList));
        if (item.action === "CustomButton") {
          // lightning版本JavaScript类型自定义按钮
          if (item.event === "lightning") {
            this.selectNum = this.checkedList.length;
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");

            // 自定义按钮
            let param = JSON.parse(item.functionCode);
            param.id = this.ids;
            param.token = this.$CCDK.CCToken.getToken();
            if (param.maxRecord && this.selectNum > param.maxRecord) {
              this.$message.warning(
                `最多只能选中${param.maxRecord}条数据进行操作`
              );
            } else {
              if (param.params && param.params.length > 0) {
                // 替换id
                param.params.forEach((item) => {
                  if (item.argValue === "{!ids}") {
                    if (this.selectNum < 1) {
                      this.$message.warning(
                        this.$i18n.t("lable.opppdt.selPdt.leastone")
                      );
                    }
                    item.argValue = this.ids;
                  }
                  if (item.argValue === "{!viewId}") {
                    item.argValue = this.viewId;
                  }
                  if (item.token === "{!binding}") {
                    item.token = this.$CCDK.CCToken.getToken();
                  }
                });
              }

              let loadingInstance = Loading.service(this.loadingOption);
              CommonObjApi.openCall(param).then((res) => {
                loadingInstance.close();
                if (res.data && res.data.action) {
                  // 刷新页面
                  if (res.data.action === "refresh") {
                    this.refresh();
                  } else if (res.data.action === "redirect") {
                    // 重定向 需判断当前窗口打开还是新窗口打开
                    if (res.data.mode === "new") {
                      window.open(res.data.url);
                    } else if (res.data.mode === "now") {
                      window.location.href = res.data.url;
                    }
                  } else if (res.data.action === "alert") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                  }
                  //先弹窗，在当前页面刷新
                  else if (res.data.action === "alert_refresh") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                  //先弹窗,在新标签页跳转
                  else if (res.data.action === "alert_redirect") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      // 重定向 需判断当前窗口打开还是新窗口打开
                      if (res.data.mode === "new") {
                        window.open(res.data.url);
                      } else if (res.data.mode === "now") {
                        if (`/${window.location.hash}` === res.data.url) {
                          window.location.reload();
                        } else {
                          window.location.href = res.data.url;
                        }
                      }
                    }, 1000);
                  }
                }
              });
            }
          }
          // 自定义按钮lightning-script
          if(item.event === 'lightning-script' && item.functionCode){
            let newFun = Function(item.functionCode)
            newFun()
          }
          if (item.event === "lightning-url") {
            let searchUrl = item.functionCode.split("?")[1];
            let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
            if (paramsObj.isNeedRecordId && paramsObj.isNeedRecordId == "1") {
              // 需要选记录
              if (this.checkedList.length == 0) {
                this.$message.warning(
                  this.$i18n.t("lable.opppdt.selPdt.leastone")
                );
                return;
              }
            }
            let ids = [];
            let idsKey = new Date().getTime();
            for (let i = 0; i < this.checkedList.length; i++) {
              ids.push(this.checkedList[i].id);
            }
            ids = ids.join(",");
            localStorage.setItem(idsKey, ids);
            // 当前窗口打开
            if (item.behavior === "self") {
              if (item.functionCode.substring(0, 7) == "cloudcc") {
                // 新版自定义页面
                let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
                let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
                let page = paramsObj.page;
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                      button: "Home",
                    },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                    },
                  });
                }
                if (paramsObj.target) {
                  if (paramsObj.target == "dialog") {
                    let obj = {
                      title: paramsObj.dialogTitle || "详情",
                      width: paramsObj.dialogWidth || "50%",
                      pageApi: page,
                      idsKey: idsKey,
                    };
                    this.$bus.$emit("openDialog", obj);
                  } else if (paramsObj.target == "window") {
                    window.open(
                      routeData.href,
                      "newWindow",
                      "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                    );
                  }
                } else {
                  window.open(routeData.href, "_self");
                }
              } else {
                if (item.functionCode.indexOf("?") == -1) {
                  // 说明链接里面没有用？号拼接参数
                  window.location.href = `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`;
                } else {
                  // 说明链接里面用？号拼接参数了
                  window.location.href = `${item.functionCode}&viewId=${this.viewId}&idsKey=${idsKey}`;
                }
              }
            } else {
              if (item.functionCode.substring(0, 7) == "cloudcc") {
                let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
                let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
                let page = paramsObj.page;
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                      button: "Home",
                    },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                    },
                  });
                }
                window.open(routeData.href, "_blank");
              } else {
                if (item.functionCode.indexOf("?") == -1) {
                  // 说明链接里面没有用？号拼接参数
                  window.open(
                    `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`
                  );
                } else {
                  // 说明链接里面用？号拼接参数了
                  window.open(
                    `${item.functionCode}&viewId=${this.viewId}&idsKey=${idsKey}`
                  );
                }
              }
            }
          }
          if (item.event === "JavaScript" || item.event === "URL") {
            // JavaScript和URL类型提示切换系统
            this.$message.warning(this.$i18n.t("lable.Please.switch"));
          }
        }
        // 指定在延迟开始前调用
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    // 监听route变化，更新对象api和id
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      this.objId = nval.params.objId;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
// 自定义气泡样式
.button-list-popper {
  margin-top: 14px !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;

  .buttonListBox {
    max-height: 644px;
    overflow: auto;

    .buttonList {
      padding: 4px 4px 0 4px;

      .item {
        font-size: 12px;
        color: #080707;
        margin-bottom: 4px;
        padding: 6px 12px;
        cursor: pointer;

        &:hover {
          background: #f5f5f5;
          border-radius: $--border-radius-base;
        }
      }

      .button-item {
        font-size: 14px;
      }
    }

    .otherButtons {
      border-top: 1px solid #e1e1e1;
    }
  }
}

.more-button-popper {
  margin: 14px 0 0 0 !important;
  transform: translateX(-22px);
  .buttonList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .button-item {
      font-size: 14px;
    }
  }
}

.button-list-box {
  padding: 0 !important;

  > span {
    display: inline-block;
    padding: 9px;
  }

  .first-button {
    padding-right: 12px;
    margin-right: 10px;
    border-right: 0.5px solid #fff;
  }
}
.button-point:hover {
  background: #f3f7ff !important;
}
</style>
<style lang="scss" scoped>
.myButton {
  height: $--cloudcc-base-height;
  display: flex;
  align-items: center;
  border-radius: $--border-radius-base;
  // background-color: $--color-primary;
  color: #080707;
  border: 1px solid #e1e1e1 !important;
  cursor: pointer;
  .one {
    padding: 9px 8px;
  }
  // 分割线
  ::v-deep .el-divider--vertical {
    margin: 0;
  }
  .iBox {
    padding: 10px 6px;
  }
}

.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  height: 32px;
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }
  .quickbook {
    height: 32px;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #dedcda;
    box-shadow: none;
    font-size: 12px;
    cursor: pointer;
    color: #006dcc;
    margin: 0;
    border-radius: 0px;
  }
  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
      span:first-child {
        // border-right: 1px solid #dedcda;
      }
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        width: 29px;
        height: 27px;
        font-size: 18px;
        vertical-align: baseline;
        overflow: inherit;
        border-left: 1px solid #dedcda;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
.masstransfer {
  height: 18px;
  font-size: 18px;
  margin-top: 34px;
  margin-left: 35px;
  font-weight: bold;
  color: #666666;
  display: inline-block;
  line-height: 18px;
}
/*.al::-webkit-scrollbar-track {
  !*display: none;*!
  width: 5px;
}*/
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}
</style>
