<template>
  <div class="myFiterButton">
    <!-- 筛选器触发按钮 -->
    <el-button
      size="small"
      class="filterBtn buttonClass"
      @mouseout.native="filterIcon = '#icon-Normal-Screen'"
      @mouseover.native="filterIcon = '#icon-Click-Screen'"
      @click="handleClick('filterBoard')"
    >
      <svg class="icon" aria-hidden="true" style="height: 14px; width: 14px">
        <use :href="filterIcon"></use>
      </svg>
    </el-button>
  </div>
</template>

<script>
/**
 * 过滤器触发按钮组件
 */
export default {
  data() {
    return {
      filterIcon: "#icon-Normal-Screen",
    };
  },
  methods: {
    /**
     * 筛选器点击事件
     * @param {String} action 绑定的点击事件名称
     */
    handleClick(action) {
      this.$emit(action);
    },
  },
};
</script>
<style lang="scss" scoped>
.myFiterButton {
  position: relative;
  .filterBtn {
    padding: 8px 9px !important;
    margin-right: 8px;

    > span {
      height: 12px;
      display: inline-block;
    }
  }
  .buttonClass {
    &:hover {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:active {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:focus {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
  }
}
</style>